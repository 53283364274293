import styled from "styled-components"
import { Link } from "react-router-dom"

const MOB_SCREEN_WIDTH = 500

export const COLOURS = {
	text1: "#1F2124",
	text2: "#737679",
	hover: "#EAEAF1",
	grey: "#B4B8BC",
	blue: "#0066FF",
}

export const Spacer = styled.div<{ hoz?: boolean; size: any }>`
	display: flex;
	width: ${props => (props.hoz ? props.size + "px" : "100%")};
	height: ${props => (!props.hoz ? props.size + "px" : "100%")};
	flex-shrink: 0;
`

const FontStack = `
    font-family: 'DM Sans', sans-serif;
`

export const BasicText = `
    ${FontStack}
    font-size: 16px;
    line-height: 24px;
    color: ${COLOURS.text2};
    font-weight: 400;
`

export const TitleText = `
    ${FontStack}
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
`

export const HeadingText = `
    font-family: 'DM Serif Display', serif;
    font-size: 40px;
    line-height: 40px;
    color: ${COLOURS.text1};
    font-weight: 400;

    @media only screen and (max-width: ${MOB_SCREEN_WIDTH}px) {
		font-size: 32px;
        line-height: 40px;
	}
`

const ButtonStyles = `
    position: relative;
    text-align: left;
    padding: 8px 16px;
    min-height: 40px;
    margin: 0;
    border: 0;
    outline: 0;
    cursor: pointer;
    border-radius: 0;
    background: ${COLOURS.text1};
    ${TitleText}
    color: white;
    transition: all 0.2s;
    -webkit-tap-highlight-color: transparent;

    &.center {
        text-align: center;
    }

    &.hasIcon {
        display: flex;
        align-items: center;
        padding-top: 4px;
        padding-bottom: 4px;

        svg {
            padding-right: 4px;
            // margin-left: auto;
        }
    }

    &.alignLeft {
        margin-left: -16px;
    }

    .hiddenInput {
        position: absolute;
        opacity: 0;
        right: -999px;
    }

    div {
		${BasicText}
	}

	svg {
        transition: color 0.2s;
        color: ${COLOURS.grey};
        
        > * {
            pointer-events: none;
        }
    }

    @media only screen and (min-width: ${MOB_SCREEN_WIDTH}px) {
        &:hover {
            background: ${COLOURS.hover};
            color: ${COLOURS.text1};
            
            > div, > svg, div > svg {
                color: ${COLOURS.blue};
            }
        }
    }

    @media only screen and (max-width: ${MOB_SCREEN_WIDTH}px) {
        &:active {
            background: ${COLOURS.hover};
            color: ${COLOURS.text1};
            
            > div, > svg, div > svg {
                color: ${COLOURS.blue};
            }
        }
    }
`

export const Button = styled.button`
	${ButtonStyles}
`

const GhostButtonStyles = `
    ${ButtonStyles}
    font-size: 16px;
    font-weight: 400;
    color: ${COLOURS.text2};
    background: white;

    svg {
        vertical-align: middle;
    }
`

const AnchorStyles = `
    text-decoration: none;
    
    > div {
        color: ${COLOURS.blue};
    }
`

const IconButtonStyles = `
    width: 32px;
    height: 32px;
    padding: 0;

    &:hover {
        background: white;
    }
`

export const GhostButton = styled.button`
	${GhostButtonStyles}
`

export const GhostButtonLink = styled.a`
	${GhostButtonStyles}
	${AnchorStyles}
    box-sizing: border-box;
	display: block;
`

export const GhostButtonIcon = styled.button`
	${GhostButtonStyles}
	${IconButtonStyles}
    border-radius: 0;
`

export const MenuButtonStyles = `
    ${GhostButtonStyles}
    ${AnchorStyles}
    font-size: 18px;
    width: 100%;
    background: transparent;
	padding: 8px 24px;
	border-radius: 0;
    box-sizing: border-box;
    
    @media only screen and (max-width: ${MOB_SCREEN_WIDTH}px) {
        padding: 16px 40px;
    }

    &.disabled {
        pointer-events: none;
        color: ${COLOURS.text1};
    }
`

export const MenuButton = styled.a`
	${MenuButtonStyles}
`

export const RouterMenuButton = styled(Link)`
	${MenuButtonStyles}
`

export const LouLamRouterButton = styled(Link)`
	${AnchorStyles}
	pointer-events: all;
`
