import * as React from "react"
import { COLOURS } from "./Core"

const defaultIconColour = COLOURS.text1

export const GridSVG = (
	<svg
		width="32px"
		height="32px"
		viewBox="0 0 32 32"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g
			id="GridSVG"
			stroke="none"
			strokeWidth="1"
			fill="none"
			fillRule="evenodd"
		>
			<path
				d="M14,18 L14,26 L6,26 L6,18 L14,18 Z M26,18 L26,26 L18,26 L18,18 L26,18 Z M26,6 L26,14 L18,14 L18,6 L26,6 Z M14,6 L14,14 L6,14 L6,6 L14,6 Z"
				id="Combined-Shape"
				fill={defaultIconColour}
			></path>
		</g>
	</svg>
)

export const ColumnSVG = (
	<svg
		width="32px"
		height="32px"
		viewBox="0 0 32 32"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g
			id="ColumnSVG"
			stroke="none"
			strokeWidth="1"
			fill="none"
			fillRule="evenodd"
		>
			<path
				d="M22,18 L22,26 L10,26 L10,18 L22,18 Z M22,6 L22,14 L10,14 L10,6 L22,6 Z"
				id="Combined-Shape"
				fill={defaultIconColour}
			></path>
		</g>
	</svg>
)

export const ChevronSVG = (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="24"
		height="24"
		viewBox="0 0 24 24"
	>
		<g
			fill="none"
			fillRule="evenodd"
			stroke="none"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth="1"
		>
			<path stroke={COLOURS.grey} strokeWidth="3" d="M5 15L12 9 19 15"></path>
		</g>
	</svg>
)

export const MenuSVG = (
	<svg
		width="32"
		height="32"
		viewBox="0 0 32 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		style={{ marginRight: "4px" }}
	>
		<rect width="32" height="32" fill="none" />
		<path d="M4 11H28V13H4V11Z" fill={COLOURS.text2} />
		<path d="M4 19H28V21H4V19Z" fill={COLOURS.text2} />
	</svg>
)

export const LinkOutSVG = (
	<svg
		width="32"
		height="32"
		viewBox="0 0 32 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M17 6H23H24H26V8V9V15H23V11L18.2218 15.7782L16.2218 13.7782L21 9H17V6ZM9 23H23V19H26V23V26H23H9H6V23V9V6H9H13V9H9V23Z"
			fill="currentColor"
		/>
	</svg>
)

export const EnquireSVG = (
	<svg
		width="32"
		height="32"
		viewBox="0 0 32 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M12 18H11.1396L10.41 18.456L7 20.5873V9H24V18H12ZM4 26L7 24.125L12 21H24H27V18V9V6H24H7H4V9V22.4623V26ZM10.5 15C11.3284 15 12 14.3284 12 13.5C12 12.6716 11.3284 12 10.5 12C9.67157 12 9 12.6716 9 13.5C9 14.3284 9.67157 15 10.5 15ZM17 13.5C17 14.3284 16.3284 15 15.5 15C14.6716 15 14 14.3284 14 13.5C14 12.6716 14.6716 12 15.5 12C16.3284 12 17 12.6716 17 13.5ZM20.5 15C21.3284 15 22 14.3284 22 13.5C22 12.6716 21.3284 12 20.5 12C19.6716 12 19 12.6716 19 13.5C19 14.3284 19.6716 15 20.5 15Z"
			fill={defaultIconColour}
		/>
	</svg>
)

export const ShippingSVG = (
	<svg
		width="32"
		height="32"
		viewBox="0 0 32 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M6 11.2609V24.7391L13 29L26 20.7391V7.26087L19 3L6 11.2609ZM9 14.843V23.0532L11.5 24.5749V16.3647L9 14.843ZM14.5 16.3458V24.4924L23 19.091V10.9445L14.5 16.3458ZM21.7487 8.18516L19.0341 6.53281L10.2684 12.103L12.983 13.7553L21.7487 8.18516Z"
			fill={defaultIconColour}
		/>
	</svg>
)

export const EmailSVG = (
	<svg
		width="32"
		height="32"
		viewBox="0 0 32 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M24 11H8V21H24V11ZM5 8V24H27V8H5Z"
			fill={defaultIconColour}
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M16 15.5603L20.1803 11H11.8197L16 15.5603ZM15.2628 19.1958C15.6592 19.6282 16.3408 19.6282 16.7372 19.1958L27 8H5L15.2628 19.1958Z"
			fill={defaultIconColour}
		/>
	</svg>
)
