import * as React from "react"
import { useState, useEffect, useRef } from "react"
import { TransitionGroup, CSSTransition } from "react-transition-group"
import {
	Switch,
	Route,
	Link,
	useLocation,
	Redirect,
	useHistory,
} from "react-router-dom"
import styled from "styled-components"
// eslint-disable-next-line
import { MenuSVG, EnquireSVG, ShippingSVG, EmailSVG } from "./SVGs"
import {
	BasicText,
	HeadingText,
	Spacer,
	GhostButton,
	GhostButtonLink,
	RouterMenuButton,
	TitleText,
	COLOURS,
} from "./Core"

const MOB_SCREEN_WIDTH = 500,
	IS_MOB = window.innerWidth <= MOB_SCREEN_WIDTH,
	HEADER_SPACING = IS_MOB ? 84 + 32 : 96 + 40,
	ART_HEADER_SPACING = IS_MOB ? 84 + 120 : 96 + 120,
	ART_HEADER_PHY_SCROLL = IS_MOB ? 0 : 1200,
	PAINTING_0_WIDTH = IS_MOB ? 180 : 465

const Page = styled.div`
	--max-page-width: 600px;
	--col-padding: 48px;

	${BasicText}
	position: relative;
	display: flex;
	flex-direction: column;
	flex-shrink: 0;
	width: 100vw;
	min-height: ${window.innerHeight}px;
	margin: 0 auto;
	background: #fff;
	overflow: hidden;
	overflow-y: auto;
	transition: all 0.6s;
	align-items: center;
	-webkit-overflow-scrolling: touch;

	&.image-zoomed {
		cursor: zoom-out;
	}

	> * {
		flex-shrink: 0;
	}
`
const H1Styles = `
	${HeadingText}
	margin: 0;
	text-transform: uppercase;
`

const Title = styled.div`
	${H1Styles};
`
const HeroH1 = styled.h1`
	${H1Styles};
	position: absolute;
	display: none;
	z-index: 6;
	top: 35vh;
	margin: 0 auto;
	color: white;
	font-size: 160px;
	pointer-events: none;

	&.homeTransition {
		display: block;
	}

	@media only screen and (max-width: ${MOB_SCREEN_WIDTH}px) {
		font-size: 60px;
	}
`

const MenuCard = styled.div`
	position: absolute;
	display: flex;
	flex-direction: column;
	min-width: 128px;
	z-index: 3;
	padding: 8px 0;
	background: white;
	box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.18);
	box-shadow: none;
	${BasicText}
	opacity: 0;
	visibility: hidden;
	transition: all 0.2s, 0s visibility 0.2s, z-index 0s;
	cursor: default;
	white-space: pre-wrap;

	top: 0;
	right: 100%;

	> div {
		width: 100%;
		padding: 0 24px;
		box-sizing: border-box;

		@media only screen and (max-width: ${MOB_SCREEN_WIDTH}px) {
			padding: 0 40px;
		}
	}

	.title {
		${TitleText}
	}

	/* Mobile */
	@media only screen and (max-width: ${MOB_SCREEN_WIDTH}px) {
		width: calc(100vw - 64px);
		margin: 56px 32px;
		left: -24px;
		padding: 8px 0 16px;
		box-shadow: 0 24px 100px rgba(0, 0, 0, 0.3);
	}
`

const Header = styled.div<{ hide?: boolean }>`
	position: relative;
	display: flex;
	justify-content: center;
	width: calc(100% - 2px);
	position: fixed;
	top: 0;
	z-index: 2;
	padding: 24px 0 32px;
	will-change: transform;
	pointer-events: none;
	background: rgba(255, 255, 255, 0.8);
	backdrop-filter: saturate(200%) blur(50px);
	transition: all 0.3s;

	@media only screen and (max-width: ${MOB_SCREEN_WIDTH}px) {
		padding: 20px 0 24px;
	}

	&.isZoomed {
		opacity: 0;
		transition: opacity 0.2s;
	}

	&.homeTransition,
	&.hidden {
		opacity: 0;
	}

	.content {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		max-width: var(--max-page-width);
		pointer-events: none;

		.buttons {
			position: absolute;
			display: flex;

			&.left {
				left: 0;
			}
			&.right {
				right: 0;
			}

			button {
				background: transparent;
				pointer-events: all;

				&.menuButton {
					font-size: 18px;
					cursor: default;
					opacity: 0;
					pointer-events: none;
					transition: opacity 0.4s;

					&.show {
						pointer-events: all;
						opacity: 1;
					}

					&.showMenu > ${MenuCard} {
						opacity: 1;
						visibility: visible;
						transition: all 0.2s, visibility 0s;
					}

					> svg {
						padding: 0px;
					}
				}
			}
		}

		@media only screen and (max-width: ${MOB_SCREEN_WIDTH}px) {
			width: calc(100% - 80px);
		}

		.h1-fade-enter {
			transform: translateY(16px);
			opacity: 0;
		}
		.h1-fade-enter-active {
			transform: translateY(0);
			opacity: 1;
			transition: 1s all 1s;
		}
	}
`

const ContentColumn = styled(TransitionGroup)`
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;

	.my-fade-enter {
		opacity: 0;
	}
	.my-fade-enter-active {
		opacity: 1;
		transition: 0.4s opacity 0.4s;
	}
	.my-fade-exit {
		opacity: 1;
	}
	.my-fade-exit-active {
		opacity: 0;
		transition: opacity 0.4s;
	}
`

const ContentContainer = styled.div`
	position: absolute;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 100%;
	/* max-width: var(--max-page-width); */
	min-height: ${window.innerHeight}px;
	background: #fff;
	padding-left: calc((100vw - var(--max-page-width)) / 2);
	padding-right: calc((100vw - var(--max-page-width)) / 2);
	box-sizing: border-box;
	/* margin-bottom: 120px; */
	z-index: 1;

	&.screenWidth {
		max-width: 100vw;
		padding: 0;
	}

	p {
		text-align: left;
		width: 100%;
		padding: 0;
		margin: 0;

		&.pageWidth {
			max-width: var(--max-page-width);
			margin: 0 auto;
		}
	}

	.aboutPhoto {
		width: 100vw;
	}

	@media only screen and (max-width: ${MOB_SCREEN_WIDTH}px) {
		margin-bottom: 288px;
		padding: 0 40px;

		&:not(.screenWidth) {
			box-sizing: border-box;
		}

		p.pageWidth {
			padding: 0 40px;
			box-sizing: border-box;
		}
	}
`

const Paintings = styled(ContentContainer)`
	align-items: center;
	opacity: 1;
	will-change: marginTop;
	backface-visibility: hidden;

	&.mobile {
		margin-top: 140px;
	}

	&.homeTransition {
		z-index: 4;
		pointer-events: none;

		.painting {
			opacity: 0;
		}

		#Painting0 {
			pointer-events: none;
			z-index: 4;
			opacity: 1;
			overflow: visible;

			> img {
				transform: scale(${window.innerWidth / PAINTING_0_WIDTH}) translateZ(0);
				transition: filter 0.6s;
				transform-origin: top;
				box-shadow: none;
				will-change: transform, marginTop;
				backface-visibility: hidden;
			}

			.details {
				opacity: 0;
			}
		}
	}
`

const Painting = styled.div`
	position: relative;
	transition: all 0.3s, z-index 0s;
	transform-origin: center;
	max-width: 100%;
	margin: 0 0 64px 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;
	user-select: none;
	background: white;
	opacity: 1;
	border-radius: 4px;
	will-change: transform;

	&.zoomed,
	&.zoomed:hover {
		cursor: zoom-out;

		.details {
			opacity: 0;
		}

		img {
			box-shadow: none;
			transition: all 0.4s;
		}
	}

	&.inFront {
		z-index: 3;
	}

	> img {
		cursor: zoom-in;
		z-index: 1;
		max-width: 100%;
		height: 350px;
		object-fit: contain;
		transform-origin: center;
		transition: all 0.3s;
		box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
		filter: blur(0px);
		image-rendering: pixelated;
		user-select: none;
	}

	&.lowres {
		overflow: hidden;
		cursor: progress;

		img {
			filter: blur(10px);
			pointer-events: none;
			box-shadow: none;
		}
	}

	&.noTransition {
		transition: none;

		> img {
			transition: none;
		}
	}

	.details {
		position: absolute;
		z-index: 0;
		width: 180px;
		top: 0%;
		left: 100%;
		padding: 0;
		margin: 0;
		padding-left: 32px;
		margin-bottom: 16px;
		${BasicText}
		opacity: 0;
		transition: all 0.3s;
		white-space: pre-wrap;

		> p {
			display: block;
			width: 100%;
		}

		.title {
			text-transform: uppercase;
			font-size: 18px;
		}

		.contact {
			color: ${COLOURS.blue};
			text-decoration: none;
		}
	}

	/* Desktop */
	@media only screen and (min-width: ${MOB_SCREEN_WIDTH}px) {
		.details {
			visibility: hidden;
		}

		&:not(.zoomed) {
			img:hover ~ .details,
			.details:hover {
				opacity: 1;
				visibility: visible;
			}
		}
	}

	/* Mobile */
	@media only screen and (max-width: ${MOB_SCREEN_WIDTH}px) {
		&.lowres img {
			width: 100%;
			height: 100%;
		}

		> img {
			max-height: 300px;
			height: auto;
		}

		.details {
			position: relative;
			left: 0;
			opacity: 1;
			width: calc(100vw - 80px);
			max-width: none;
			padding: 0;
			margin: 0;
			margin-top: 24px;
			text-align: center;

			> p {
				text-align: center;
			}
		}
	}
`

const ZoomBackground = styled.div`
	position: fixed;
	display: block;
	visibility: hidden;
	opacity: 0;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: white;
	z-index: 2;
	transition: opacity 0.3s, 0s visibility 0.3s;

	&.show {
		visibility: visible;
		opacity: 1;
		transition: opacity 0.3s, visibility 0s;
	}
`

const ZoomCover = styled.div<{ show: boolean }>`
	position: absolute;
	display: ${props => (props.show ? "block" : "none")};
	top: 0;
	left: 0;
	right: 0;
	height: 100vh;
	background: transparent;
	z-index: 4;
	cursor: zoom-out;
`

const Footer = styled.div`
	position: relative;
	z-index: 0;
	display: flex;
	justify-content: center;
	align-self: center;
	width: 100vw;
	padding: 48px;
	margin-top: auto;
	box-sizing: border-box;
	background: ${COLOURS.hover};

	.content {
		display: flex;
		justify-content: center;
		width: var(--max-page-width);

		> div,
		> a {
			color: ${COLOURS.text1};
			text-decoration: none;
			padding: 0 8px;
			margin: 0 16px;
		}

		/* Mobile */
		@media only screen and (max-width: ${MOB_SCREEN_WIDTH}px) {
			flex-direction: column;

			> div,
			> a {
				padding: 8px 0;
				margin: 0 0 16px;
			}
		}
	}
`

export default function App() {
	//
	let location = useLocation()
	let history = useHistory()

	const myPage = useRef(null)
	const myHeader = useRef(null)
	var myFirstPainting = useRef(null)
	var myPaintings = useRef(null)
	const myHeroH1 = useRef(null)
	const isZoomedRef = useRef(false)
	const isRootRef = useRef(false)
	const observerRef = useRef(null)
	const pageScrollPos = useRef(0)

	const [isZoomed, setIsZoomed] = useState(false)
	const [isMobile, setIsMobile] = useState(false)
	const [zoomedIndex, setZoomedIndex] = useState(null)
	const [menuCardOpen, setMenuCardOpen] = useState(false)
	const [showCopySuccess, setShowCopySuccess] = useState(false)
	const [isHome, setIsHome] = useState(false)
	const [hideHeader, setHideHeader] = useState(false)

	const zoomOutDone = e => {
		const imgBox = e.currentTarget

		imgBox.removeEventListener("transitionend", zoomOutDone)
		imgBox.classList.remove("inFront")
	}

	const zoomOutThisImage = imgBox => {
		imgBox.classList.remove("noTransition")

		imgBox.addEventListener("transitionend", zoomOutDone)
		imgBox.classList.remove("zoomed")
		imgBox.style.transform = "translate(0, 0) translateZ(0)"
		imgBox.querySelector("img").style.transform = "scale(1)"

		setIsZoomed(false)
		isZoomedRef.current = false
		setZoomedIndex(undefined)
	}

	const exitZoom = _isZoomed => {
		//
		if (!_isZoomed) return false

		const imgBox = document.getElementsByClassName("zoomed")[0]
		zoomOutThisImage(imgBox)
	}

	const handleKeyPress = e => {
		//
		if (!isZoomedRef.current) return false

		e.preventDefault()

		var zI = parseInt(myPage.current.dataset.zoomedIndex)

		switch (e.keyCode) {
			case 27:
			case 32:
				exitZoom(true)
				return false
			case 37:
			case 38:
				zI -= 1
				break
			case 39:
			case 40:
				zI += 1
				break
			default:
				return false
		}

		if (zI < 0 || zI > PaintingsList.length - 1) {
			exitZoom(true)
			return false
		}

		zoomThisImage(document.getElementById("Painting" + zI), true)
	}

	const zoomThisImage = (imgBox, isSwapping) => {
		//
		if (isSwapping) {
			const zoomed = document.getElementsByClassName("zoomed")[0]
			zoomed.classList.remove("zoomed", "inFront")
			zoomed.classList.add("noTransition")
			// @ts-ignore
			zoomed.style.transform = "translate(0, 0) translateZ(0)"
			zoomed.querySelector("img").style.transform = "scale(1)"
			zoomed.classList.remove("noTransition")

			imgBox.classList.add("noTransition")
		}

		const img = imgBox.querySelector("img"),
			{ width: w, height: h, x, y } = img.getBoundingClientRect(),
			{ innerWidth: vw, innerHeight: vh } = window

		const barHeight = isMobile ? 0 : 0,
			rX = (vw - 0) / w,
			rY = (vh - barHeight) / h,
			zoom = Math.min(rX, rY),
			vh_2 = (vh - barHeight) / 2,
			vw_2 = (vw - 0) / 2,
			imgCenterY = y + h / 2,
			imgCenterX = x + w / 2

		const tY = vh_2 - imgCenterY + 0,
			tX = vw_2 - imgCenterX

		imgBox.style.transform = `translate(${tX}px, ${tY}px) translateZ(0)`
		img.style.transform = `scale(${zoom}) translateZ(0)`

		imgBox.classList.add("zoomed", "inFront")

		setIsZoomed(true)
		isZoomedRef.current = true
		setZoomedIndex(imgBox.dataset.index)
	}

	const handleScroll = e => {
		//
		setMenuCardOpen(false)
		exitZoom(isZoomedRef.current)

		const _isMobile = window.innerWidth <= MOB_SCREEN_WIDTH,
			scroll = Math.max(e.currentTarget.scrollTop, 0), //window.scrollY, // 200
			wasBigScroll = Math.abs(scroll - pageScrollPos.current) > 5,
			isInHomeArea = scroll < ART_HEADER_PHY_SCROLL + 120

		if (wasBigScroll && isRootRef.current && !isInHomeArea) {
			setHideHeader(scroll > pageScrollPos.current)
		}

		if (isInHomeArea) setHideHeader(false)

		pageScrollPos.current = scroll

		if (isRootRef.current && !_isMobile) {
			//
			const windowSize = _isMobile ? window.innerHeight : window.innerWidth,
				maxScale = windowSize / PAINTING_0_WIDTH - 1, // 2 - 1 = 1
				phyScroll = ART_HEADER_PHY_SCROLL, // 1000
				visScroll = ART_HEADER_SPACING, // 176
				scrollFactor = 1 + visScroll / phyScroll, // 1.176
				pScroll = Math.min(scroll, phyScroll) / phyScroll, // 20%
				dScale = (1 - pScroll) * maxScale, // 80% * 1 = 0.8
				scale = Math.max(1 + dScale, 1) // 1 + 0.8 = 1.8

			myFirstPainting.current.style.transform = `scale(${scale}) translateZ(0)`

			var top = Math.min(phyScroll * scrollFactor, scroll * scrollFactor)
			top = Math.round(top)

			myPaintings.current.style.marginTop = `${top}px`

			myHeroH1.current.style.opacity = 1 - pScroll * 4
			myHeroH1.current.style.marginTop = `${Math.min(phyScroll, scroll)}px`

			setIsHome(scroll < phyScroll)
		}
	}

	useEffect(() => {
		//
		if (window.innerWidth <= MOB_SCREEN_WIDTH) setIsMobile(true)

		document.addEventListener("keydown", handleKeyPress)
	}, [])

	const observerOptions = {
		root: null,
		rootMargin: "0px",
		threshold: 0,
	}

	const handleIntersectChange = entries => {
		entries.forEach(entry => {
			//
			const painting = entry.target,
				isLowRes = painting.classList.contains("lowres"),
				isOnScreen = entry.intersectionRatio > 0

			if (isLowRes && isOnScreen) {
				const img = painting.querySelector("img"),
					highres = `/${painting.dataset.filename}.jpg`

				var tempImg = new Image()
				tempImg.onload = () => {
					//
					img.src = tempImg.src
					setTimeout(() => {
						painting.classList.remove("lowres")
					}, 200)
				}
				tempImg.src = highres
			}
		})
	}

	useEffect(() => {
		//
		var { pathname: p, hash: h } = location,
			isRoot = p === "/",
			isArtPage = p + h === "/#art",
			isBadHash = Boolean(h) && (h !== "#art" || !isRoot),
			_isMobile = window.innerWidth <= MOB_SCREEN_WIDTH,
			y = isArtPage ? ART_HEADER_PHY_SCROLL + (_isMobile ? 0 : 64) : 0

		if (isBadHash) return history.push("/")

		setTimeout(() => {
			setHideHeader(false)
			myPage.current.scrollTo(0, y)
			pageScrollPos.current = y
		}, 400)

		setMenuCardOpen(false)
		setIsHome(isRoot && !isArtPage && !_isMobile)
		isRootRef.current = isRoot

		myFirstPainting.current = document.querySelector("#Painting0 img")
		myPaintings.current = document.querySelector("#Paintings")

		if (observerRef.current) observerRef.current.disconnect()

		if (isRoot || isArtPage) {
			//
			observerRef.current = new IntersectionObserver(
				handleIntersectChange,
				observerOptions
			)

			const paintings = [...document.querySelectorAll(".painting")]
			paintings.forEach(p => observerRef.current.observe(p))
		}
		//
	}, [location])

	useEffect(() => {
		if (showCopySuccess) setTimeout(() => setShowCopySuccess(false), 2000)
	}, [showCopySuccess])

	const renderPainting = (i, { title, fileName, specs, specsMob }) => (
		<Painting
			id={`Painting${i}`}
			className="painting lowres"
			key={i}
			data-index={i}
			data-filename={fileName}
		>
			<img
				src={`/${fileName}_low.jpg`}
				alt={title}
				onClick={e => zoomThisImage(e.currentTarget.parentNode, false)}
				draggable="false"
			/>
			<div className="details">
				<p className="title">{title}</p>
				<Spacer size="8" />
				{isMobile ? specsMob : specs}
				<Spacer size="8" />
				<GhostButtonLink
					className={`
						${isMobile ? "center" : "alignLeft"}
					`}
					aria-label="compose-email-button"
					href={`mailto:hello@loulam.com?subject=RE: ${title}`}
				>
					Contact artist
				</GhostButtonLink>
			</div>
		</Painting>
	)

	const renderMenuButton = (i, { title, hash }) => {
		const { pathname: p, hash: h } = location,
			destination = p + h,
			url = hash + title.toLowerCase(),
			isMatch = destination === `/${url}`,
			_isRoot = destination === "/",
			isDisabled = title === "Art" ? isMatch || _isRoot : isMatch

		return (
			<RouterMenuButton
				key={i}
				to={`/${url}`}
				onClick={() => {}}
				className={isDisabled ? "disabled" : ""}
				aria-label={`nav-button-${title}`}
			>
				{title}
			</RouterMenuButton>
		)
	}

	const renderFooter = () => {
		return (
			<Footer>
				<div className="content">
					<div>© Lou Lam {new Date().getFullYear()}</div>
					<Link to="/#art">Art</Link>
					<Link to="/about">About</Link>
					<Link to="/contact">Contact</Link>
				</div>
			</Footer>
		)
	}

	return (
		<Page
			ref={myPage}
			id="page"
			className={`
				${isHome ? "homeTransition" : ""}
			`}
			data-zoomed-index={zoomedIndex}
			onScroll={handleScroll}
		>
			<Header
				ref={myHeader}
				className={`
					${isHome ? "homeTransition" : ""} 
					${hideHeader ? "hidden" : ""}
					${isZoomed ? "isZoomed" : ""}
				`}
			>
				<div className="content">
					<div className="buttons left">
						<GhostButton
							className={`
									menuButton hasIcon alignLeft
									${false ? "" : "show"}
									${menuCardOpen ? "showMenu" : ""}
								`}
							aria-label="menu-button"
							onMouseEnter={() => setMenuCardOpen(true)}
							onMouseLeave={() => setMenuCardOpen(false)}
							onTouchEnd={() => setMenuCardOpen(!menuCardOpen)}
						>
							{MenuSVG}
							<MenuCard>
								{Pages.map((page, i) => renderMenuButton(i, page))}
							</MenuCard>
						</GhostButton>
					</div>

					<Title>Lou Lam</Title>
				</div>
			</Header>

			<HeroH1
				ref={myHeroH1}
				className={`
					${isHome ? "homeTransition" : ""}
				`}
			>
				Lou Lam
			</HeroH1>

			<ContentColumn>
				<CSSTransition key={location.key} classNames="my-fade" timeout={800}>
					<Switch location={location}>
						<Route exact path="/">
							<Paintings
								id="Paintings"
								className={`
									${isHome ? "homeTransition" : ""}
									${isMobile ? "mobile" : ""}
								`}
							>
								{PaintingsList.map((p, i) => renderPainting(i, p))}
								<Spacer size="40" />
								<ZoomBackground className={isZoomed ? "show" : ""} />

								{renderFooter()}
							</Paintings>

							<ZoomCover
								style={{ top: isZoomed ? myPage.current.scrollTop : 0 }}
								show={isZoomed}
								onClick={() => exitZoom(true)}
							/>
						</Route>
						<Route exact path="/about">
							<ContentContainer className="screenWidth">
								<Spacer size={HEADER_SPACING} />
								{AboutBody}
								<Spacer size="64" />
								<img
									className="aboutPhoto"
									src={`/${isMobile ? "mob" : "wide"}-portrait.jpg`}
									alt="Lou Lam Portrait"
								/>

								{renderFooter()}
							</ContentContainer>
						</Route>
						<Route exact path="/contact">
							<ContentContainer>
								<Spacer size={HEADER_SPACING} />
								{EnquireSVG}
								<Spacer size="12" />
								<p>
									For information regarding existing work, requests for
									commissions, or any general enquiries, please email the artist
									at the address below.
								</p>
								<Spacer size="16" />
								<div style={{ display: "flex" }}>
									<GhostButtonLink
										className="alignLeft" // "hasIcon alignLeft"
										aria-label="compose-email-button"
										href="mailto:hello@loulam.com"
									>
										<div>hello@loulam.com</div>
										{/* {LinkOutSVG} */}
									</GhostButtonLink>
									<GhostButton
										className="" // "alignLeft"
										aria-label="copy-email-button"
										onClick={() => {
											// @ts-ignore
											document.getElementById("EmailToCopy").select()
											document.execCommand("copy")
											setShowCopySuccess(true)
										}}
									>
										{showCopySuccess ? "Copied!" : "Copy email"}
										<textarea
											id="EmailToCopy"
											className="hiddenInput"
											value="hello@loulam.com"
											readOnly
										/>
									</GhostButton>
								</div>
								<Spacer size="48" />
								{ShippingSVG}
								<Spacer size="12" />
								<p>Worldwide shipping available. Contact artist for details.</p>
								<Spacer size="80" />

								{renderFooter()}
							</ContentContainer>
						</Route>
						<Route path={"*"}>
							<Redirect to="/" />
						</Route>
					</Switch>
				</CSSTransition>
			</ContentColumn>
		</Page>
	)
}

const Pages = [
	{
		title: "Art",
		hash: "#",
	},
	{
		title: "About",
		hash: "",
	},
	{
		title: "Contact",
		hash: "",
	},
]

const PaintingsList = [
	{
		fileName: "JINK",
		title: `JINK`,
		specs: `Acrylic and ink on canvas, \n40" x 30", \n2020`,
		specsMob: `Acrylic and ink on canvas, 40" x 30", 2020`,
	},
	{
		fileName: "DIMSUM_AT_DAWN",
		title: `DIM SUM AT DAWN`,
		specs: `Acrylic on canvas, \n48" x 60", \n2020`,
		specsMob: `Acrylic on canvas, 48" x 60", 2020`,
	},
	{
		fileName: "OKINAWA_GYRE",
		title: `OKINAWA GYRE`,
		specs: `Acrylic on canvas, \n48" x 60", \n2020`,
		specsMob: `Acrylic on canvas, 48" x 60", 2020`,
	},
	{
		fileName: "FARRAGO",
		title: `FARRAGO`,
		specs: `Acrylic and ink on canvas, \n48" x 60", \n2020`,
		specsMob: `Acrylic and ink on canvas, 48" x 60", 2020`,
	},
	{
		fileName: "HONG_KONG_FERVOR",
		title: `HONG KONG FERVOR`,
		specs: `Acrylic on canvas, \n48" x 36", \n2020`,
		specsMob: `Acrylic on canvas, 48" x 36", 2020`,
	},
	{
		fileName: "Lam_Tsuen_River",
		title: `LAM TSUEN RIVER`,
		specs: `Acrylic on canvas, \n30" x 40", \n2020`,
		specsMob: `Acrylic on canvas, 30" x 40", 2020`,
	},
	{
		fileName: "Quiet_of_Mind",
		title: `QUIET OF MIND`,
		specs: `Acrylic on canvas, \n36" x 48", \n2020`,
		specsMob: `Acrylic on canvas, 36" x 48", 2020`,
	},
	{
		fileName: "Perseverance",
		title: `PERSEVERANCE`,
		specs: `Acrylic on canvas, \n36" x 48", \n2020`,
		specsMob: `Acrylic on canvas, 36" x 48", 2020`,
	},
	{
		fileName: "Haven",
		title: `HAVEN`,
		specs: `Acrylic on canvas, \n36" x 48", \n2020`,
		specsMob: `Acrylic on canvas, 36" x 48", 2020`,
	},
	{
		fileName: "GLOAMING_WALL_GHOST",
		title: `GLOAMING WALL GHOST`,
		specs: `Acrylic on canvas, \n30" x 40", \n2020`,
		specsMob: `Acrylic on canvas, 30" x 40", 2020`,
	},
	{
		fileName: "Afternoon_Wall_Ghosts",
		title: `AFTERNOON WALL GHOSTS`,
		specs: ` Acrylic on canvas, \n30" x 40", \n2020`,
		specsMob: `Acrylic on canvas, 30" x 40", 2020`,
	},
	{
		fileName: "The_Empty_Shelf",
		title: `THE EMPTY SHELF`,
		specs: `Acrylic on canvas, \n30" x 40", \n2020`,
		specsMob: `Acrylic on canvas, 30" x 40", 2020`,
	},
	{
		fileName: "ALLAY_STUDY_1",
		title: `ALLAY STUDY #1`,
		specs: `Acrylic on canvas, \n36" x 48", \n2020`,
		specsMob: `Acrylic on canvas, 36" x 48", 2020`,
	},
	{
		fileName: "ALLAY_STUDY_2",
		title: `ALLAY STUDY #2`,
		specs: `Acrylic on canvas, \n48" x 36", \n2020`,
		specsMob: `Acrylic on canvas, 48" x 36", 2020`,
	},
]

const AboutBody = (
	<>
		<p className="pageWidth">
			Louise (Sizer) Lam is an Asia based British painter specialising in
			abstract expressionism. Lam trained at Central Saint Martins and Chelsea
			College of Arts London in Fine Art Painting and Graphic Design. She is
			currently living and working in Hong Kong.
		</p>
	</>
)

{
	/*
	<Spacer size="24" />
	<MenuButton
		className="hasIcon"
		target="_blank"
		href="https://www.instagram.com"
	>
		Instagram
		{LinkOutSVG}
	</MenuButton>
	*/
}
